.App {
  text-align: center;
  background-image: url('../public/background.png');
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.App-logo {
  width: 100vmin;
  pointer-events: none;
}

.App-intro{
  color: rgb(255, 255, 255);
  font-size: 1.4vh;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  margin: 0 0 1em 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  padding: 10px;
  font-family: 'Zen Antique Soft', serif;
}

.App-body-guide{
  background-color: rgba(255, 255, 255, 0.5);
  font-weight: bolder;
  text-shadow: 1px 1px 5px rgb(255, 255, 255);
  font-size: 1.6vh;
  /* border-radius: 20px; */
  padding: 0px 3px;
  text-align: center;
  box-shadow: 0 0 5px rgba(255, 255, 255, 1.0);
}

.App-header {
  /* min-height: 100vh; */
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-footer a{
  color: white;
}